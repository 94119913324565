import * as React from 'react';
import styled from 'styled-components';
import { Container, Typography } from '@mui/material';
import logo from '../icons/spendora-icon.svg';
import { useLocation } from 'react-router-dom';
import humanizeString from 'humanize-string';

const StyledContainer = styled(Container)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledImg = styled.img`
  max-width: 128px;
`;

function EmailChangeConfirmed() {
  const location = useLocation();
  const hash = location.hash.substring(1);
  const params = hash ? new URLSearchParams(hash) : null;
  const error = params ? params.get('error') : null;
  const errorDescription = params ? params.get('error_description') : null;
  return (
    <StyledContainer>
      <StyledImg src={logo} alt="Logo" />
      <Typography
        variant="h4"
        component="h4"
        gutterBottom
        textAlign="center"
        fontWeight={600}
        paddingTop={0}
      >
        Spendora
      </Typography>

      <Typography
        variant="h4"
        component="h4"
        gutterBottom
        textAlign="center"
        fontWeight={600}
        paddingTop={8}
        color={error ? 'error' : null}
      >
        {error ? humanizeString(error) : 'Your email change has been confirmed'}
      </Typography>
      <Typography
        variant="p"
        component="p"
        gutterBottom
        textAlign="center"
        color={error ? 'error' : null}
      >
        {error || errorDescription
          ? errorDescription || humanizeString(error)
          : 'The email address associated with your account has been changed.'}
      </Typography>
    </StyledContainer>
  );
}

export default EmailChangeConfirmed;
