import * as React from 'react';
import styled from 'styled-components';
import { Container, Typography } from '@mui/material';
import logo from '../icons/spendora-icon.svg';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import downloadGoogle from '../assets/download-google.png';
import downloadApple from '../assets/download-apple.svg';
import dashboardImg from '../assets/dashboard.png';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
`;

const StyledImg = styled.img`
  max-width: 128px;
`;

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const DownloadButton = styled.img`
  height: 64px;
  margin: 16px;
`;

const AppShowcase = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f7fafc;
  align-items: center;
`;

const AppShowcaseImg = styled.img`
  height: 500px;
  margin: 16px;
`;
const AppShowcaseContent = styled.div`
  margin: 16px;
  max-width: 550px;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
  padding-bottom: 64px;
`;

const FeatureBox = styled.div`
  padding: 16px;
  max-width: 350px;
`;

function Invite() {
  const { id } = useParams();

  const { user, accountName } = jwtDecode(id);

  return (
    <>
      <StyledContainer>
        <StyledImg src={logo} alt="Logo" />
        <Typography
          variant="h4"
          component="h4"
          textAlign="center"
          fontWeight={600}
          paddingTop={0}
        >
          Spendora
        </Typography>

        <Typography
          variant="h4"
          component="h4"
          gutterBottom
          textAlign="center"
          fontWeight={600}
          paddingTop={8}
        >
          YOU WERE INVITED TO {accountName.toUpperCase()} BY{' '}
          {user.toUpperCase()}
        </Typography>
        <Typography variant="p" component="p" gutterBottom textAlign="center">
          Please follow these steps:
        </Typography>
        <ol>
          <li>
            Download the Spendora app from the Apple App Store or Google Play
            Store
          </li>
          <li>Sign up for Spendora within the app</li>
          <li>
            Reopen the referral link (make sure you're still logged into the
            app)
          </li>
          <li>Click "Accept Invite"</li>
          <li>You're all set — enjoy using Spendora!</li>
        </ol>
        <DownloadWrapper>
          <a
            href="https://apps.apple.com/app/id6461728276"
            target="_blank"
            rel="noreferrer"
          >
            <DownloadButton
              src={downloadApple}
              alt="Download on the App Store"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=co.spendora.app"
            target="_blank"
            rel="noreferrer"
          >
            <DownloadButton src={downloadGoogle} alt="Get it on Google Play" />
          </a>
        </DownloadWrapper>
      </StyledContainer>
      <AppShowcase>
        <AppShowcaseImg src={dashboardImg} />
        <AppShowcaseContent>
          <Typography
            variant="h4"
            component="h4"
            gutterBottom
            textAlign="left"
            fontWeight={600}
          >
            Spendora is the personal finance and budget tracking app for you
          </Typography>
          <Typography variant="p" component="p" gutterBottom textAlign="left">
            Full visibility on your expenditure and earnings through our
            analytics. Spendora categorizes all your transactions and provides
            advance insights into your spending behaviour
          </Typography>
        </AppShowcaseContent>
      </AppShowcase>
      <Typography
        variant="h4"
        component="h4"
        margin={8}
        textAlign="center"
        fontWeight={600}
      >
        Features
      </Typography>
      <FeaturesWrapper>
        <FeatureBox>
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            textAlign="left"
            fontWeight={600}
          >
            Analytics
          </Typography>
          <Typography variant="p" component="p" gutterBottom textAlign="left">
            Monitor and get insights into your overall expenses and earnings
          </Typography>
        </FeatureBox>
        <FeatureBox>
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            textAlign="left"
            fontWeight={600}
          >
            Insights
          </Typography>
          <Typography variant="p" component="p" gutterBottom textAlign="left">
            Categorize each transaction to understand your spending pattern and
            cash flow health
          </Typography>
        </FeatureBox>
        <FeatureBox>
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            textAlign="left"
            fontWeight={600}
          >
            Split Expenses
          </Typography>
          <Typography variant="p" component="p" gutterBottom textAlign="left">
            Track shared finances with partner, friends or family. Know how much
            you’re owned or owe to settle up
          </Typography>
        </FeatureBox>
        <FeatureBox>
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            textAlign="left"
            fontWeight={600}
          >
            Budget
          </Typography>
          <Typography variant="p" component="p" gutterBottom textAlign="left">
            Create unlimited budgets and be notified when you spend above
            threshold to help stay on track
          </Typography>
        </FeatureBox>
        <FeatureBox>
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            textAlign="left"
            fontWeight={600}
          >
            Currency Conversion
          </Typography>
          <Typography variant="p" component="p" gutterBottom textAlign="left">
            Track foreign transactions in your home currency with live exchange
            rates
          </Typography>
        </FeatureBox>
        <FeatureBox>
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            textAlign="left"
            fontWeight={600}
          >
            Payment Reminder
          </Typography>
          <Typography variant="p" component="p" gutterBottom textAlign="left">
            Receive notifications for upcoming payments and never be late on
            your bills or loan repayment again
          </Typography>
        </FeatureBox>
      </FeaturesWrapper>
    </>
  );
}

export default Invite;
