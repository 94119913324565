import * as React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './Router';

export default function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
