import * as React from 'react';

import { createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Invite from './pages/Invite';
import EmailVerified from './pages/EmailVerified';
import EmailChangeConfirmed from './pages/EmailChangeConfirmed';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/invite/:id',
    element: <Invite />
  },
  {
    path: '/email_verified',
    element: <EmailVerified />
  },
  {
    path: '/email_change_confirmed',
    element: <EmailChangeConfirmed />
  }
]);

export default router;
