import * as React from 'react';
import styled from 'styled-components';
import { Container, Typography } from '@mui/material';
import logo from '../icons/spendora-icon.svg';

const StyledContainer = styled(Container)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function Home() {
  return (
    <StyledContainer maxWidth="sm">
      <img src={logo} alt="Logo" />

      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        textAlign="center"
        fontWeight={600}
        paddingTop={8}
      >
        Spendora
      </Typography>
    </StyledContainer>
  );
}

export default Home;
